import Vue from 'vue';
import Router from 'vue-router';

import RouterComponent from './components/RouterComponent.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "start" */ './views/main/Start.vue'),
      children: [
        {
          path: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
          path: 'recover-password',
          component: () => import(/* webpackChunkName: "recover-password" */ './views/PasswordRecovery.vue'),
        },
        {
          path: 'reset-password',
          component: () => import(/* webpackChunkName: "reset-password" */ './views/ResetPassword.vue'),
        },
        {
          path: 'verify-account',
          component: () => import(/* webpackChunkName: "verify-account" */ './views/VerifyAccount.vue'),
        },
        {
          path: 'main',
          component: () => import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
          children: [
            {
              path: 'dashboard',
              component: () => import(/* webpackChunkName: "main-dashboard" */ './views/main/Dashboard.vue'),
            },
            {
              path: 'profile',
              component: RouterComponent,
              redirect: 'profile/view',
              children: [
                {
                  path: 'view',
                  component: () => import(
                    /* webpackChunkName: "main-profile" */ './views/main/profile/UserProfile.vue'),
                },
                {
                  path: 'edit',
                  component: () => import(
                    /* webpackChunkName: "main-profile-edit" */ './views/main/profile/UserProfileEdit.vue'),
                },
                {
                  path: 'password',
                  component: () => import(
                    /* webpackChunkName: "main-profile-password" */ './views/main/profile/UserProfileEditPassword.vue'),
                },
              ],
            },
            {
              path: 'admin',
              component: () => import(/* webpackChunkName: "main-admin" */ './views/main/admin/Admin.vue'),
              redirect: 'admin/users/all',
              children: [
                {
                  path: 'users',
                  redirect: 'users/all',
                },
                {
                  path: 'users/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/AdminUsers.vue'),
                },
                {
                  path: 'users/edit/:id',
                  name: 'main-admin-users-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-edit" */ './views/main/admin/EditUser.vue'),
                },
                {
                  path: 'users/create',
                  name: 'main-admin-users-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-create" */ './views/main/admin/CreateUser.vue'),
                },
                {
                  path: 'users/:id/journal',
                  name: 'main-admin-users-journal',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/journal/Journal.vue'),
                },
                {
                  path: 'users/:user_id/journal/create',
                  name: 'main-admin-journal-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/journal/CreateJournal.vue'),
                },
                {
                  path: 'users/:user_id/journal/:id',
                  name: 'main-admin-journal-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/journal/EditJournal.vue'),
                },
                {
                  path: 'users/:id/alerts',
                  name: 'main-admin-users-alerts',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/Alerts.vue'),
                },
                {
                  path: 'users/:user_id/alerts/create/crossing',
                  name: 'main-admin-alerts-create-crossing',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/CreateAlertCrossing.vue'),
                },
                {
                  path: 'users/:user_id/alerts/create/channel',
                  name: 'main-admin-alerts-create-channel',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/CreateAlertChannel.vue'),
                },
                {
                  path: 'users/:user_id/alerts/create/delta',
                  name: 'main-admin-alerts-create-delta',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/CreateAlertDelta.vue'),
                },
                {
                  path: 'users/:user_id/alerts/create/delta/percent',
                  name: 'main-admin-alerts-create-delta-percent',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/CreateAlertDelta.vue'),
                },
                {
                  path: 'users/:user_id/alerts/:id',
                  name: 'main-admin-alerts-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/EditAlert.vue'),
                },
                {
                  path: 'feedbacks/all',
                  name: 'main-admin-feedbacks-all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-feedbacks" */ './views/main/admin/AdminFeedbacks.vue'),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/*', redirect: '/',
    },
  ],
});

import { IFeedback, IPagingResponse, IUserProfile } from '@/interfaces';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setUsers(state: AdminState, payload: IUserProfile[]) {
        state.users = payload;
    },
    setUser(state: AdminState, payload: IUserProfile) {
        const users = state.users.filter((user: IUserProfile) => user.id !== payload.id);
        users.push(payload);
        state.users = users;
    },
    setFeedbacks(state: AdminState, payload: IPagingResponse<IFeedback>){
        state.feedbacksInfo = {
            pageNumber: payload.page_number,
            pageSize: payload.page_size,
            feedbacks: payload.data,
            total: payload.total
        }
    }
};

const { commit } = getStoreAccessors<AdminState, State>('');

export const commitSetUser = commit(mutations.setUser);
export const commitSetUsers = commit(mutations.setUsers);

export const commitSetFeedbacks = commit(mutations.setFeedbacks);

import {api} from '@/api';
import {ActionContext} from 'vuex';
import {
  IAlertCreateChannel,
  IAlertCreateCrossing,
  IAlertCreateDelta,
  IAlertUpdate, IFeedbackResponseCreate, IJournal,
  IUserProfileCreate,
  IUserProfileUpdate,
} from '@/interfaces';
import {State} from '../state';
import {AdminState} from './state';
import {getStoreAccessors} from 'typesafe-vuex';
import {commitSetUsers, commitSetUser, commitSetFeedbacks} from './mutations';
import {dispatchCheckApiError} from '../main/actions';
import {commitAddNotification, commitRemoveNotification} from '../main/mutations';
import {AxiosError} from 'axios';

type MainContext = ActionContext<AdminState, State>;

export const actions = {
  async actionGetUsers(context: MainContext) {
    try {
      const response = await api.getUsers(context.rootState.main.token);
      if (response) {
        commitSetUsers(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionUpdateUser(context: MainContext, payload: { id: number, user: IUserProfileUpdate }) {
    try {
      const loadingNotification = {content: 'saving', showProgress: true};
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.updateUser(context.rootState.main.token, payload.id, payload.user),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {content: 'User successfully updated', color: 'success'});
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionCreateUser(context: MainContext, payload: IUserProfileCreate) {
    try {
      const loadingNotification = {content: 'saving', showProgress: true};
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.createUser(context.rootState.main.token, payload),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {content: 'User successfully created', color: 'success'});
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetAlerts(context: MainContext, ownerId: number) {
    try {
      const response = await api.getAlerts(context.rootState.main.token, ownerId);
      if (response) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetAlert(context: MainContext, id: number) {
    try {
      const response = await api.getAlert(context.rootState.main.token, id);
      if (response) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionCreateAlertCrossing(context: MainContext, payload: IAlertCreateCrossing) {
    try {
      const loadingNotification = {content: 'saving', showProgress: true};
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.createAlertCrossing(context.rootState.main.token, payload),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {content: 'Alert successfully created', color: 'success'});
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionCreateAlertChannel(context: MainContext, payload: IAlertCreateChannel) {
    try {
      const loadingNotification = {content: 'saving', showProgress: true};
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.createAlertChannel(context.rootState.main.token, payload),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {content: 'Alert successfully created', color: 'success'});
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionCreateAlertDelta(context: MainContext, payload: IAlertCreateDelta) {
    try {
      const loadingNotification = {content: 'saving', showProgress: true};
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.createAlertDelta(context.rootState.main.token, payload),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {content: 'Alert successfully created', color: 'success'});
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionUpdateAlert(context: MainContext, payload: { id: number, alert: IAlertUpdate }) {
    try {
      const loadingNotification = {content: 'saving', showProgress: true};
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.updateAlert(context.rootState.main.token, payload.id, payload.alert),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {content: 'Alert successfully updated', color: 'success'});
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetJournalEntries(context: MainContext, ownerId: number) {
    try {
      const response = await api.getJournalEntries(context.rootState.main.token, ownerId);
      if (response) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetJournalEntry(context: MainContext, id: number) {
    try {
      const response = await api.getJournalEntry(context.rootState.main.token, id);
      if (response) {
        return response.data;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionCreateJournalEntry(context: MainContext, payload: IJournal) {
    try {
      const loadingNotification = {content: 'saving', showProgress: true};
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.createJournalEntry(context.rootState.main.token, payload),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {content: 'Entry successfully created', color: 'success'});
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionUpdateJournalEntry(context: MainContext, payload: { id: number, entry: IJournal }) {
    try {
      const loadingNotification = {content: 'saving', showProgress: true};
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.updateJournalEntry(context.rootState.main.token, payload.id, payload.entry),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {content: 'Entry successfully updated', color: 'success'});
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetFeedbacks(context: MainContext,  payload: { pageSize?: number; pageNumber?: number }) {
  try {
    const response = await api.getFeedbacks(context.rootState.main.token, payload.pageSize ?? 10, payload.pageNumber ?? 0);
      if (response) {
        commitSetFeedbacks(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionSendFeedbackResponse(context: MainContext, payload: IFeedbackResponseCreate) {
    let closeModal = true;
    const loadingNotification = {content: 'saving', showProgress: true};
    commitAddNotification(context, loadingNotification);
    try {
      const response = (await Promise.all([
        api.sendFeedbackResponse(context.rootState.main.token, payload),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitAddNotification(context, {content: 'Feedback response successfully sent', color: 'success'});
    } catch (error) {
      closeModal = false;
      commitAddNotification(context, {content: 'Failed to create feedback response', color: 'error'});
      await dispatchCheckApiError(context, error as AxiosError);
    } finally {
      commitRemoveNotification(context, loadingNotification);
    }
    return closeModal;
  },
};

const {dispatch} = getStoreAccessors<AdminState, State>('');

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);

export const dispatchGetAlerts = dispatch(actions.actionGetAlerts);
export const dispatchGetAlert = dispatch(actions.actionGetAlert);
export const dispatchUpdateAlert = dispatch(actions.actionUpdateAlert);

export const dispatchCreateAlertCrossing = dispatch(actions.actionCreateAlertCrossing);
export const dispatchCreateAlertChannel = dispatch(actions.actionCreateAlertChannel);
export const dispatchCreateAlertDelta = dispatch(actions.actionCreateAlertDelta);

export const dispatchGetJournalEntries = dispatch(actions.actionGetJournalEntries);
export const dispatchGetJournalEntry = dispatch(actions.actionGetJournalEntry);
export const dispatchCreateJournalEntry = dispatch(actions.actionCreateJournalEntry);
export const dispatchUpdateJournalEntry = dispatch(actions.actionUpdateJournalEntry);

export const dispatchGetFeedbacks = dispatch(actions.actionGetFeedbacks);
export const dispatchSendFeedbackResponse = dispatch(actions.actionSendFeedbackResponse);

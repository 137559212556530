import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify, {
  iconfont: 'md',
    theme: {
    primary: '#3781a4',
    secondary: '#004E6E',
    accent: '#EE982B',
    error: '#b71c1c',
  },
});

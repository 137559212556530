import axios from 'axios';
import {apiUrl} from '@/env';
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
  IAlert,
  IAlertUpdate,
  IAlertCreateCrossing,
  IAlertCreateChannel,
  IAlertCreateDelta, IJournal, IFeedback, IPagingResponse, IFeedbackResponseCreate,
} from './interfaces';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async verifyAccount(token: string) {
    return axios.post(`${apiUrl}/api/v1/verify-account/`, {token});
  },
  async getAlerts(token: string, ownerId: number) {
    return axios.get<IAlert[]>(`${apiUrl}/api/v1/alerts/?owner_id=${ownerId}`, authHeaders(token));
  },
  async getAlert(token: string, id: number) {
    return axios.get<IAlert>(`${apiUrl}/api/v1/alerts/${id}`, authHeaders(token));
  },
  async createAlertCrossing(token: string, data: IAlertCreateCrossing) {
    return axios.post(`${apiUrl}/api/v1/alerts/crossing`, data, authHeaders(token));
  },
  async createAlertChannel(token: string, data: IAlertCreateChannel) {
    return axios.post(`${apiUrl}/api/v1/alerts/channel`, data, authHeaders(token));
  },
  async createAlertDelta(token: string, data: IAlertCreateDelta) {
    return axios.post(`${apiUrl}/api/v1/alerts/delta`, data, authHeaders(token));
  },
  async updateAlert(token: string, alertId: number, data: IAlertUpdate) {
    return axios.put(`${apiUrl}/api/v1/alerts/${alertId}`, data, authHeaders(token));
  },
  async getJournalEntries(token: string, ownerId: number) {
    return axios.get<IJournal[]>(`${apiUrl}/api/v1/journal/?owner_id=${ownerId}`, authHeaders(token));
  },
  async getJournalEntry(token: string, id: number) {
    return axios.get<IJournal>(`${apiUrl}/api/v1/journal/${id}`, authHeaders(token));
  },
  async createJournalEntry(token: string, data: IJournal) {
    return axios.post(`${apiUrl}/api/v1/journal`, data, authHeaders(token));
  },
  async updateJournalEntry(token: string, entryId: number, data: IJournal) {
    return axios.put(`${apiUrl}/api/v1/journal/${entryId}`, data, authHeaders(token));
  },
  async getFeedbacks(token: string, pageSize: number, pageOffset: number) {
    const params = new URLSearchParams();
    params.append('skip', pageOffset.toString());
    params.append('limit', pageSize.toString());

    return axios.get<IPagingResponse<IFeedback>>(`${apiUrl}/api/v1/feedbacks/all`, {...authHeaders(token), params});
  },
  async sendFeedbackResponse(token: string, {feedbackId, response}: IFeedbackResponseCreate) {
    return axios.post(`${apiUrl}/api/v1/feedbacks/${feedbackId}/respond`, { response }, {...authHeaders(token)});
  },
};

import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { AdminState } from './state';

const defaultState: AdminState = {
  users: [],
  feedbacksInfo: {
    total: 0,
    pageSize: 25,
    pageNumber: 0,
    feedbacks: []
  }
};

export const adminModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
